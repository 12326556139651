{
  "name": "revitauto",
  "version": "0.0.79",
  "scripts": {
    "ng": "ng",
    "start": "ng serve -o --port 4200",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.1.0",
    "@angular/cdk": "^17.1.0",
    "@angular/common": "^17.1.0",
    "@angular/compiler": "^17.1.0",
    "@angular/core": "^17.1.0",
    "@angular/fire": "^17.0.1",
    "@angular/forms": "^17.1.0",
    "@angular/google-maps": "^17.3.3",
    "@angular/material": "^17.3.6",
    "@angular/platform-browser": "^17.1.0",
    "@angular/platform-browser-dynamic": "^17.1.0",
    "@angular/router": "^17.1.0",
    "@google/generative-ai": "^0.21.0",
    "@googlemaps/markerclusterer": "^2.5.3",
    "@ngrx/store": "^17.1.0",
    "@swimlane/ngx-charts": "^20.5.0",
    "d3": "^7.9.0",
    "firebase": "^10.14.1",
    "geofire-common": "^6.0.0",
    "html2canvas": "^1.4.1",
    "jspdf": "^2.5.1",
    "jspdf-autotable": "^3.8.2",
    "jspdf-html2canvas": "^1.5.2",
    "material-icons": "^1.13.12",
    "moment": "^2.30.1",
    "moment-timezone": "^0.5.46",
    "ng2-pdf-viewer": "^10.2.2",
    "ngx-color-picker": "^16.0.0",
    "ngx-loading": "^17.0.0",
    "ngx-mask": "^17.0.9",
    "ngx-material-timepicker": "^13.1.1",
    "rxjs": "~7.8.0",
    "signature_pad": "^5.0.4",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.1.0",
    "@angular/cli": "^17.1.0",
    "@angular/compiler-cli": "^17.1.0",
    "@npmcli/package-json": "^6.0.0",
    "@types/d3": "^7.4.3",
    "@types/google.maps": "^3.55.7",
    "@types/jasmine": "~5.1.0",
    "@types/node": "^20.9.0",
    "@types/npmcli__package-json": "^4.0.4",
    "autoprefixer": "^10.4.17",
    "eslint-plugin-simple-import-sort": "^12.1.1",
    "eslint-plugin-unused-imports": "^4.1.3",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.33",
    "tailwindcss": "^3.4.1",
    "typescript": "~5.3.2"
  }
}