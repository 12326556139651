export const environment = {
  appVersion: require('../../package.json').version,
  production: true,
  firebase: {
    apiKey: 'AIzaSyDGruO9cZQ5_mdx0QbdsOTCKrvaReD4b1M',
    authDomain: 'revi-auto.firebaseapp.com',
    databaseURL: 'https://revi-auto.firebaseio.com',
    projectId: 'revi-auto',
    storageBucket: 'revi-auto.appspot.com',
    messagingSenderId: '845072208903',
    appId: '1:845072208903:web:895d4d840fb52434e586a2',
    measurementId: 'G-M3C3C0RLDF',
  },
  vapidKey:
    'BEuwxhornqMOhWjhr7GyOx5C8bBFa1FonNehD7TkcupPB0ejbFcDKJ6Z-l3xOo87-kZm6Mt6Log4E9VIQfoMJ_Y',
  geminiKey: 'AIzaSyCoCwNOYy0HCxOOiwqxwgG5fuZF2VOmQxw',
};
